@import './default';
// *****IMPORTANT NOTE *****
// This file controls all the website's styling; please only make updates if you're confident in your ability to do so.
// ******************************

$breakpoints: (
  sm: 640px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  2xl: 1536px,
  3xl: 2048px,
);

// Primary colors
$primary: #0c9351;
$primary-100: #ccebdc;
$primary-200: #a1c5b4;
$primary-300: #52a07a;
$primary-400: #03743d;

// Secondary colors
$secondary: #d3b994;
$secondary-100: #f5eabd;
$secondary-200: #c5ae8d;
$secondary-300: #c5a981;
$secondary-400: #333132;

// Grayscale
$white: #ffffff;
$gray-25: #e9ecf0;
$gray-50: #f2f4f7;
$gray-100: #e9ecf0;
$gray-200: #d8dce3;
$gray-300: #bbc1c9;
$gray-400: #858f9d;
$gray-500: #576170;
$gray-600: #333132;
$gray-700: #1b2129;
$gray-dark: #0c1014;
$black: #333132;

// Background
$background-theme: #ffffff;
$background-theme-dark: #ffffff;
$background-page: #ffffff;

// Text color base
$text-color-default: #2a384e;

// Color text default and Heading (dark-theme)
$heading-color: #333132;
$body-color-text: #2a384e;

//Placeholder
$placeholder-bg: $gray-50;
$placeholder-bg-100: #ececec;
$placeholder-bg-200: #ebf1f1;
$placeholder-bg-300: #e3eff1;

$placeholder-bg-dark: rgba(43, 44, 44, 0.3);
$placeholder-bg-dark-100: rgba(76, 75, 75, 0.3);
$placeholder-bg-dark-200: rgba(33, 41, 41, 0.4);
$placeholder-bg-dark-300: rgba(43, 59, 61, 0.3);

// Border
$border-radius: 0px;
$border-radius-md: 0px;
$border-radius-sm: 0px;
$border-radius-full: 9999px;
$border-radius-pill: 16px;

// Spacing
$space-x: 16px;
$space-y: 11px;

// Transition
$transition-all: all 0.25s ease-in;

// Opacity
$opacity: 0.8;

// Overlay Color
$overlay-bg: rgba(18, 37, 33, 0.3);

// Shadow
$box-shadow: rgba(149, 157, 165, 0.2) 0px 3px 8px;
$box-shadow-2: 0px 0px 12px rgba(186, 186, 186, 0.3);
$box-shadow-3: 0px -6px 8px -6px rgb(186 186 186 / 30%);

// Font Size
$text-small: 0.55rem;
$text-xs: 0.75rem;
$text-sm: 0.875rem;
$text-base: 1rem;
$text-lg: 1.125rem;
$text-xl: 1.25rem;
$text-2xl: 1.5rem;
$text-3xl: 1.875rem;
$text-4xl: 2.25rem;
$text-5xl: 3rem;
$text-6xl: 3.75rem;
$text-7xl: 4.5rem;
$text-8xl: 6rem;
$text-9xl: 8rem;

// Base Component

// Button
$border-radius-btn: 0px;
$border-width-btn: 1px;
//primary
$btn-primary: #d3b994;
$label-btn-primary: #333132;
$border-btn-primary: #d3b994;
$btn-primary-hover: #c5a981;
$label-btn-primary-hover: #333132;
$border-btn-primary-hover: #c5a981;
$btn-primary-pressed: #c5a981;
$label-btn-primary-pressed: #333132;
$border-btn-primary-pressed: #c5a981;
$btn-primary-disabled: #d1c9be;
$label-btn-primary-disabled: #ffffff;
$border-btn-primary-disabled: #d1c9be;

//secondary
$btn-secondary: #0c9351;
$label-btn-secondary: $white;
$background-btn-secondary: #0c9351;
$btn-secondary-hover: #03743d;
$label-btn-secondary-hover: $white;
$background-btn-secondary-hover: #03743d;
$btn-secondary-pressed: #03743d;
$label-btn-secondary-pressed: $white;
$background-btn-secondary-pressed: #03743d;
$btn-secondary-disabled: #a0beb0;
$label-btn-secondary-disabled: #bbc1c9;
$background-btn-secondary-disabled: #a0beb0;

//link
$label-btn-link: #d3b994;
$label-btn-link-hove: #0c9351;

// Swiper Pagination Bullet
$swiper-bullet: $gray-400;
$swiper-bullet-active: #d3b994;

//Close
$btn-close-font-size: 20px;
$btn-close-padding: 4px;
$btn-close-border-radius: 0;
$btn-close-icon-color: #d3b994;
$btn-close-background: inherit;
$btn-close-icon-color-hover: #0c9351;
$btn-close-background-hover: inherit;
$btn-close-border-color: transparent;
$btn-close-border-color-hover: transparent;

// Input
$border-radius-input: 0px;
$input-bg: #f1f2f3;
$input-disabled-bg: #f7f8fa;
$input-color: #333132;
$input-border-color: #d3b994;
$input-border-color-hover: #c5a981;
$input-border-color-activate: #c5a981;
$input-color-label: #333132;
$input-color-label-activate: #333132;
$input-ckek-border-color: #bbc1c9;
$input-ckek-background-color: #fafbfc;
$input-ckek-background-select: #c5a981;
$input-color-label-ckeck: $gray-600;
$input-check-color: #c5a981;
$input-check-select: $white;
$input-invalid-color: #cc321b;
$input-icon-color: c5a981;
$input-icon-color-activate: c5a981;
$input-check-radio-button: c5a981;

// Badge
$border-radius-badge: 12px;
$badge: #0c9351;
$badge-counter: #0c9351;
$badge-specials: #333132;
$badge-text: #ffffff;
$badge-warning: #ffbb11;
$badge-text-warning: #664b07;

//Badge BOGO
$bagde-bogo: #d3b994;
$badge-bogo-text: #333132;
$badge-bogo-border: 1px solid#d3b994;

// Text Colors
$errors: #cc321b;
$success: #05b35e;
$hover: #d3b994;
$alert-message: #e63541;

//Select
$select-menu-bg: $white;
$select-menu-border-color: $select-menu-bg;
$select-menu-toogle-border: transparent;
$select-menu-toogle-border-hover: transparent;
$select-menu-border-radius: 0px;
$selct-thumb-color: #e9dccb;
$select-menu-item-bg-hover: #eedfc9;
$select-menu-item-bg-select: #eedfc9;
$select-menu-item-color: $black;
$select-menu-item-color-hover: $black;
$select-menu-item-color-select: $black;
$select-menu-item-shadow: 0px 0px 12px rgb(186 186 186 / 30%);

// Alert
$login-success-border-color: #333132;
$login-success-color: #333132;
$login-text-success-color: #ffffff;

$alert-errors-border-color: #f8d3cf;
$alert-errors-color: #f8d3cf;
$alert-text-errors-color: #85140a;
$alert-errors-icons-color: #85140a;

$alert-success-border-color: #a7e4bb;
$alert-success-color: #a7e4bb;
$alert-text-success-color: #0e4b22;
$alert-success-icons-color: #0e4b22;

$alert-warning-border-color: #fff1cf;
$alert-warning-color: #fff1cf;
$alert-text-warning-color: #99700a;
$alert-warning-icons-color: #99700a;

$alert-info-color: #d3e0f5;
$alert-info-border-color: #d3e0f5;
$alert-text-info-color: #0e2952;
$alert-info-icons-color: #0e2952;

$alert-border-radius: 0px;

//Express Shipping Banner
$express-banner-bg-color: #0c9351;
$express-banner-text-color: #0d0d0d;
$express-banner-bg-color-express-mode: #8ad1af;
$express-banner-text-color-express-mode: #034e2a;
$express-banner-transition-duration: 0.5s;
$express-banner-border-radius: 10px;
$express-banner-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

//Styles of the Main Components and Pages

// Age
$age-logo-max-width: 340px;
$age-card-width: 480px;
$age-logo-max-height: auto;
$age-btn-primary-border-radius: 4px;
$age-modal-shadow: $box-shadow;
$age-title: #453836;
$age-message: #b9a556;
$age-message-hover: #b9a556;
$age-link: #0c9351;
$age-background-color: #ffffff;

// CRM Modal
$crm-header-color: #333132;
$crm-background-color: #ffffff;
$crm-modal-shadow: $box-shadow;

// Not-found 404
$not-found-page: #def7eb;

// Promotions
$background-promotion: #0c9351;
$color-text-promotion: #ffffff;
$text-transform-promotions: uppercase;
$efect-promotion: #faf9f8;
$progress-color: #d3b994;

// Header
$header-logo-max-width: 150px;
$header-logo-fix-height: 56px;
$header-logo-background: none;
$header-logo-backdrop-filter: unset;
$header-logo-rounder: 0px;
$background-header: rgba(51, 49, 50, 0.98);
$header-background-image: none;
$backdrop-filter: unset;
$logo-header-spacing-left: 0px;
$logo-headder-min-width: 209px;
$header-action-icon-color: #d3b994;
$header-divider: #0c9351;
$header-input-placeholder-color: #d3b994;
$header-input-border-color: #d3b994;
$header-input-border-color-activate: #d3b994;
$header-input-background: transparent;
$header-input-border-radius: 0px;
$header-input-text-color: #ffffff;
$header-button-search-color: #d3b994;
$header-button-search-hover-color: #d3b994;
$header-button-icon-color: #333132;
$header-button-icon-hover-color: #333132;
$header-input-clear-color: ffffff;
$header-input-separator-color: transparent;

// MedRecSwitch
$med-rec-switch-background: transparent;
$med-rec-switch-border-radius: 0px;
$med-rec-switch-border-color: #d3b994;
$med-rec-switch-border-color-hover: #d3b994;
$label-btn-mode-rec: #333132;
$background-btn-mode-rec: #d3b994;
$background-btn-mode-rec-hover: #d3b994;
$label-btn-mode-med: #b3b3b3;

// Store-Selector
$background-store-selector: #333132;
$selector-btn-background: transparent;
$selector-btn-text-color: #d3b994;
$selector-btn-address-color: #fcfcfc;
$selector-icon-color: #d3b994;
$selector-heading-color: #ffffff;
$selector-mode-border-color: #d3b994;
$selector-mode-border-color-hover: #d3b994;
$selector-mode-bg: transparent;
$selector-mode-border-radius: $border-radius-md;
$label-btn-mode: #333132;
$background-btn-mode: #d3b994;
$border-color-btn-mode: #d3b994;
$label-btn-mode-secondary: #b3b3b3;
$background-btn-mode-active: $background-btn-mode;
$border-color-btn-mode-active: $border-color-btn-mode;
$label-btn-mode-secondary-active: $label-btn-mode-secondary;
$selector-store-name-item: #ffffff;
$selector-text-address-item: #fcfcfc;
$selector-current-location-color: #d3b994;

// Store-Validator
$card-validator-title-color: #333132;
$card-validator-background: #ffffff;
$card-validator-alert-border-radius: 0px;
$cart-validator-divider: #f2f4f7;
$cart-validator-text-secundary: #656565;

// GR-Limit
$limit-value: #77879d;
$limit-current-value: #0d1522;
$limit-border-color: #e9ecf0;

// Side Navigation
$background-side-nav: #333132;
$side-nav-backdrop-filter: unset;
$background-header-side-nav: #333132;
$side-nav-logo-max-width: 160px;
$side-nav-border-header: 5px solid #0c9351;
$side-nav-button-location-color: $secondary;
$side-nav-icon-button-location-color: $secondary;
$side-nav-border-main: 0px solid #0c9351;
$side-nav-color: #ffffff;
$side-nav-item-color: $secondary;
$side-nav-item-icon-color: $secondary;
$side-nav-item-weigth-icon-color: initial;
$side-nav-subitem-color: $secondary;
$side-nav-subitem-bg: #333132;
$side-nav-active-color: $secondary;
$side-nav-active-bg: #333132;
$side-nav-scroll-color: #cfbda3;

// Menu
$tab-btn: #d3b994;
$nav-text-color: #d3b994;
$nav-icon-color: #d3b994;
$background-nav: transparent;
$background-nav-image: $header-background-image;
$backdrop-filter-nav: unset;

$tab-btn-general: #0c9351;

// Mega Menu
$background-megamenu: #333132;
$megamenu-backdrop-filter: unset;
$mega-title-color: #d3b994;
$mega-text-color: #ffffff;
$mega-description-color: #ffffff;
$mega-border-color: #d3b994;
$mega-divider: #333132;
$mega-link-hover: #0c9351;
$mega-single-border-radius: 0px;
$mega-single-border-color: #d3b994;

// Search Header
$search-header-background: #ffffff;
$search-header-backdrop-filter: unset;
$search-title-color: #333132;
$search-border-color: #d3b994;
$search-see-all-link-color: #d3b994;
$background-product-card-header: #ffffff;
$search-icon-color: 0c9351;
$search-icon-color-focus: 0c9351;

// Cart Navigation
$background-shoping-cart: #ffffff;
$shoping-cart-backdrop-filter: unset;
$cart-title-color: #333132;
$cart-btn-close: #576170;
$cart-method-select: #576170;
$cart-market: #c5a981;
$cart-adderss: #333132;
$cart-divider: #f2f4f7;
$cart-footer-text: #ffffff;
$cart-help-text: #576170;
$cart-footer-price: #c5a981;
$cart-body-bacground-image: "#ffffff";
$cart-body-border-radius-image: $border-radius-md;
$cart-body-text-color: #333132;
$cart-body-text-color-secondary: #576170;
$cart-bundle-text-color: $primary;
$cart-footer-background: #333132;
$cart-footer-divider: $cart-divider;
$cart-badge-on-sale-text-color: $white;
$cart-badge-specials-text-color: $white;
$cart-active-bogo-color: #a7e4bb;
$cart-active-bogo-color-text: #a7e4bb;

//Empty-bag-gray-scale
/* $cart-empty-bag-size: 165px;
$cart-empty-bag-base-color: #656565;
$cart-empty-bag-trace-base-color: #989898;
$cart-empty-bag-side-color: #cccccc;
$cart-empty-bag-lateral-stroke: #656565;
$cart-empty-bag-front-bag: #f5f5f5;
$cart-empty-bag-signal: #e6e6e6;
$cart-empty-bag-shadow: #989898;
$cart-empty-bag-counter-badge: #656565;
$cart-empty-bag-text-counter-badge: #fafafa;
$cart-empty-bag-strap: #333333; */

//Empty-bag
$cart-empty-bag-size: 165px;
$cart-empty-bag-base-color: #b3b3b3;
$cart-empty-bag-trace-base-color: #4d4d4d;
$cart-empty-bag-side-color: #c5a981;
$cart-empty-bag-lateral-stroke: #333132;
$cart-empty-bag-front-bag: #ecd5b4;
$cart-empty-bag-signal: #ffc941;
$cart-empty-bag-shadow: #989898;
$cart-empty-bag-counter-badge: #0c9351;
$cart-empty-bag-text-counter-badge: #fafafa;
$cart-empty-bag-strap: #333132;

// Hero
$background-hero: #f7f7f7;
$border-radius-image-hero: 0px;

// Filter Treez
$background-filters-treez-modal: #ffffff;
$background-filters-treez-desktop: #ffffff;
$filter-title-section: #333132;
$filter-algolia-title: #050606;
$filter-header-close-icon: $secondary-400;
$filter-algolia-title-counter: #576170;
$filter-algolia-bage: #37404e;
$filter-icon-color: #0c9351;
$filter-control-slider-border-color: $secondary-400;
$filter-control-slider-bg-color: $secondary-300;
$filter-track-slider-border-color: $secondary-400;
$filter-track-slider-bg-color: $secondary-300;
$filter-algolia-divider: #e9ecf0;
$filter-start-active-color: #e5f3d4;
$filter-pill-background: #0c9351;
$filter-pill-text-color: #ffffff;
$filter-pill-border-color: #0c9351;
$filter-pill-border-type: solid;
$filter-background-input: #fafbfc;
$filter-background-btn-filters: #f7f7f7;
$filter-border-color-btn-filters: #bbc1c9;
$filter-text-color-btn-filters: #2a384e;
$background-color-collection: #f2f4f7;
$background-placeholder-product: #ebf0f7;

// Controls Slider
$see-all-btn: #ffffff;
$text-see-all-btn: #333132;
$see-all-link: #333132;
$see-all-counter: #c2c6cc;
$controls-slider: #333132;
$controls-border: #f7f7f7;
$controls-disabled: #ccd5e3;

// Product
$background-product-card: #ffffff;
$border-radius-card: 0px;
$discount-price: #78879d;
$discount-price-dark: #78879d;
$product-title: $heading-color;
$btn-actions-product: #333132;
$btn-actions-product-dark: #333132;
$link-inf-product: #0c9351;
$product-price: #333132;
$product-price-dark: #333132;
$product-brand: #787d87;
$product-divider: #e9ecf0;
$product-divider-default: #e9ecf0;
$product-tch-cbd: #a9b6c9;
$product-placeholder-color: #ebf0f7;
$product-placeholder-image-color: #f2f4f7;
$product-tax-applied-message: #5a6470;
$box-shadow-prodct-hover: 25px 0 20px -20px rgba(0, 0, 0, 0.08),
  -25px 0 20px -20px rgba(0, 0, 0, 0.08);

//bundle popup in card product
$background-bundle-popup: #333132;
$text-color-bundle-popup: $white;
$action-close-color: #d3b994;
$bundle-title-color: #d3b994;
$bundle-popup-item: #ffffff;
$bundle-popup-icon: #d3b994;

// Counter
$counter-border: #b3b3b3;
$counter-divider: #b3b3b3;
$counter-operation-color: #0c9351;
$counter-operation-color-hover: #d3b994;
$counter-text: #333132;
$counter-border-radius: 0px;
$counter-bacground: #fafafa;
$counter-bacground-count: #f5f5f5;

// Bundle Specials
$bundle-specials-background: #333132;
$bundle-specials-title-color: #d3b994;
$bundle-spacials-descriptions: #f7f7f7;
$bundle-specials-border-default: #c9cccb;
$bundle-specials-border-active: #d3b994;
$bundle-special-icon-default: #b9bbba;
$bundle-special-icon-active: #d3b994;
$special-placeholder-color: rgb(239, 242, 241);

//Specials Carousel
$specials-card-border-radius: 0px;

// Rating
$rating-color: $black;

// Rating & Review
$rating-btn-border-radius: 1.5rem;
$rating-review-color: #1a1919;
$rating-review-bar-color: #0c9351;
$states-btn-border-color: #333132;
$states-btn-text-color: #333132;
$states-btn-rating-color: #333132;
$states-btn-bg-color: #ffffff;
$states-btn-active-border-color: #333132;
$states-btn-active-text-color: #333132;
$states-btn-active-bg-color: #fafafa;
$states-btn-hover-border-color: #d3b994;
$states-btn-hover-text-color: #333132;
$states-btn-hover-bg-color: #d3b994;

// Footer
$footer-logo-max-width: 160px;
$footer-logo-max-height: 120px;
$background-footer: #333132;
$footer-background-image: none;
$border-top: 5px solid #0c9351;
$color-text-footer: $white;
$text-form-subscribe: $white;
$btn-background-footer: #d3b994;
$btn-background-footer-hover: #0c9351;
$text-btn-footer: #333132;
$text-btn-footer-hover: $white;
$input-border-color-footer: #d3b994;
$input-border-color-footer-hover: #0c9351;
$input-border-color-footer-activate: #0c9351;
$input-placeholder-color: #d3b994;
$input-placeholder-color-activate: $white;
$input-text-color: $white;
$input-background-footer: transparent;
$footer-link-text-color: #ffffff;
$footer-link-text-color-hover: #ffffff;
$footer-font-weight-heading: 500;
$footer-font-weight-text: 400;

// Pages
// Signup, Signin forget Pages
$auth-background-color: #333132;
$auth-border-radius-card: 0px;
$auth-background-image: none;
$auth-background-card: transparent;
$auth-headding-color: #ffffff;
$auth-text-color: #fafafa;
$auth-spinner-color: #d3b994;
$auth-logo-bottom-spacing: 0px;
$auth-logo-width: 200px;
$auth-logo-heigth: 65px;

// Account Page
$account-photo-username: #2e3061;
$account-photo-direction: #b6c4c9;
$account-price-discount: #e63e3e;
$account-btn-link: #333132;
$account-btn-link-hover: #0c9351;
$account-message-border-color: #bbc1c9;
$account-message-heading-color: #333132;
$account-message-text-color: #2a384e;
$account-tap-color: #0c9351;

// Product Detail Page
$background-detail: #ffffff;
$detail-divider: #e9ecf0;
$detail-divider-default: #e9ecf0;
$detail-sharing-icon: #0c9351;
$detail-link-category: #0c9351;
$detail-sharing-border-color: #ffffff;
$detail-border-rating-review: #e9ecf0;
$detail-counter-border: #ccd5e3;
$detail-counter-operation: #333132;
$detail-counter-value-dark: #333132;
$detail-link-change-location: #0c9351;
$detail-placeholder-color: #ebf0f7;
$detail-background-img: transparent;
$detail-counter-btn: #d3b994;
$detail-counter-label-btn: $white;
$detail-counter-border-btn: #d3b994;
$detail-counter-btn-hover: #333132;
$detail-counter-label-btn-hover: $white;
$detail-counter-border-btn-hover: #333132;
$detail-counter-btn-pressed: #333132;
$detail-counter-label-btn-pressed: $white;
$detail-counter-border-btn-pressed: #333132;
$detail-warning-btn: #2a384e;
$detail-warning-text: #576170;
$detail-stock-border-color: #333132;
$detail-stock-bg-color: #eedfc9;
$detail-stock-text-color: #333132;
$detail-background-product-info: $white;
$detail-border-radius-product-info: 0px;
$detail-props-badge-bg: transparent;
$detail-props-badge-text-color: #656565;
$detail-props-badge-border-color: #656565;
$detail-icon-props: #333132;
$detail-label-props: $secondary-400;

//Active Bogo
$active-bogo: #eedfc9;
$active-bogo-text: #333132;
$active-bogo-border: none;

//weight-variant
$detail-weight-variant-btn-border-type: dashed;
$detail-weight-variant-btn-border: #a6acb4;
$detail-weight-variant-btn-text-color: #222222;
$detail-weight-variant-btn-bg-color: transparent;
$detail-weight-variant-btn-active-border: #222222;
$detail-weight-variant-btn-active-text-color: #222222;
$detail-weight-variant-btn-active-bg-color: #eedfc9;
$detail-weight-variant-btn-hover-border: #a6acb4;
$detail-weight-variant-btn-hover-text-color: #222222;
$detail-weight-variant-btn-hover-bg-color: #eedfc9;

//Blogs
$image-border-radius: 0px;

$related-post-background: inherit;
$related-post-title-color: initial;
$related-post-time-color: initial;
$related-post-marging-bottom: 40px;

$blog-filter-bage: $secondary;
$blog-filter-bage-color: #ffffff;

$blog-header-input-placeholder-color: #cccccc;
$blog-header-input-border-color: #d3b994;
$blog-header-input-border-color-activate: #d3b994;
$blog-header-input-background: #fafafa;
$blog-header-input-border-radius: 0px;
$blog-header-input-text-color: #333132;
$blog-header-button-search-color: #d3b994;
$blog-header-button-search-hover-color: #d3b994;
$blog-header-button-icon-color: #333132;
$blog-header-button-icon-hover-color: #333132;
$blog-header-input-clear-color: d3b994;
$blog-header-input-separator-color: $header-input-border-color;

//Store Locations
$store-locator-background: #ffffff;
$store-locator-background-modal: #fafafa;

$store-locator-placeholder-map-background: rgba(0, 0, 0, 0.6);

$store-status-border-radius: 4px;
$store-status-closed-border-color: #f8d3cf;
$store-status-closed-color: #f8d3cf;
$store-status-closed-text-color: #85140a;

$store-status-open-border-color: #a7e4bb;
$store-status-open-color: #a7e4bb;
$store-status-open-text-color: #0e4b22;

$store-locator-info-border-radius: 0px;
$store-locator-info-background: #fcfcfc;
$store-locator-info-title: #0d0d0d;
$store-locator-info-text: #656565;

$store-locator-input-placeholder-color: #656565;
$store-locator-input-border-color: #d3b994;
$store-locator-input-border-color-activate: #d3b994;
$store-locator-input-background: #fafafa;
$store-locator-input-border-radius: 0px;
$store-locator-input-text-color: #333132;
$store-locator-button-search-color: #d3b994;
$store-locator-button-search-hover-color: #d3b994;
$store-locator-button-icon-color: #333132;
$store-locator-button-icon-hover-color: #333132;
$store-locator-input-clear-color: d3b994;
$store-locator-input-separator-color: $header-input-border-color;

//Order-Detail Page
$order-status-color: $btn-primary;
$order-status-bg-color: transparent;
$order-status-border-color: $btn-primary;
$order-status-active-color: $label-btn-primary;
$order-status-bg-active-color: $btn-primary;
$order-status-border-active-color: $btn-primary;

//Thank You Page
$thanku-banner-text: #4b5e66;
$thanku-banner-title: #0d0d0d;
$thanku-banner-order-number: #eff4f5;
$thanku-order-detail-title: #212121;
$thanku-thead: #6d8992;
$thanku-tbody: #062028;
$thanku-quantity: #4b5e66;
$thanku-status-icon-bg: #cccccc;
$thanku-status-active-icon-bg: #0c9351;
$thanku-status-label-color: #656565;
$thanku-status-active-label-color: #0d0d0d;
$thanku-status-separator-dot-color: #b3b3b3;
$thanku-status-active-separator-dot-color: #0c9351;
$thanku-product-tag-bg: #f0f0f0;
$thanku-product-tag-color: #4b5e66;
$thanku-product-name-color: #0d0d0d;
$thanku-product-qty-bg: #cccccc;
$thanku-product-qty-color: #0d0d0d;
$thanku-order-details-color: #656565;
$thanku-order-details-total-paid-color: #0d0d0d;
$thanku-billing-item-color: #656565;
$thanku-billing-payment-type-bg: #ebf0f7;
$thanku-billing-payment-type-color: #495870;
$thanku-notes-link-color: #528829;
$thanku-picture-path-shadow: #656565;
$thanku-picture-path-shadow-line: #989898;
$thanku-picture-path-popper-bands: #808080;
$thanku-picture-path-popper-body: #e6e6e6;
$thanku-picture-path-popper-hole: #808080;
$thanku-picture-path-popper-hole-shadow: #333333;
$thanku-picture-path-particle-group-1: #ffc941;
$thanku-picture-path-particle-group-2: #528829;
$thanku-picture-path-particle-group-3: #85bb5c;
$thanku-picture-path-particle-group-4: #e44e41;

// Utils Product flower types
$flower-type-default-bg-color: #db9317;
$flower-type-default-color: #ffffff;
$flower-type-sativa-bg-color: #9d1b20;
$flower-type-sativa-color: #ffffff;
$flower-type-indica-bg-color: #252c62;
$flower-type-indica-color: #ffffff;
$flower-type-hybrid-bg-color: #038446;
$flower-type-hybrid-color: #ffffff;
$flower-type-vape-bg-color: #007795;
$flower-type-vape-color: #ffffff;
$flower-type-extract-bg-color: #198f48;
$flower-type-extract-color: #ffffff;
$flower-type-cbd-bg-color: #c62221;
$flower-type-cbd-color: #ffffff;
$flower-type-si-bg-color: #f2a900;
$flower-type-si-color: #ffffff;
$flower-type-is-bg-color: #7d3d7d;
$flower-type-is-color: #ffffff;

//Padding Section
$padding-section: 18px;
$padding-section-mobile: 12px;

$logo-text-padding-bottom: 40px;

//Margin Section
$margin-section: 18px;
$margin-section-mobile: 12px;

//Padding Slice
$padding-slice-mobile-small: 0;
$padding-slice-desktop-small: 0;

$padding-slice-mobile-medium: 12px;
$padding-slice-desktop-medium: 18px;

$padding-slice-mobile-large: 24px;
$padding-slice-desktop-large: 36px;
